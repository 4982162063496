import { Injectable } from "@angular/core";
import { environment } from '../../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { User, UserAccessAuth, UserListParams } from "app/core/interfaces/users.interface";
import { changeToParam } from "app/core/helpers/http.helper";
import { HttpResponse } from 'app/core/interfaces/http.inteface';
import { map } from 'rxjs';
import { ActionConfig } from '../../interfaces/action.interface';
import { ActionService } from '../helper/action.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    baseUrl = `${environment.API_URL}/api/secure/users`;

    constructor(private httpClient: HttpClient,
                private actionService: ActionService) { }

    getList(params: Partial<UserListParams>) {
        const url = `${this.baseUrl}`;
        const convertedParams = changeToParam(params);
        return this.httpClient.get<HttpResponse<User[]>>(url, { params: convertedParams });
    }

    getDetail(params: any) {
        const url = `${this.baseUrl}/${params}`
        return this.httpClient.get<HttpResponse<User>>(url).pipe(map((resp) => {
            if (resp.data.profileImageFile) {
                resp.data.profileImageFile = 'data:image/png;base64, ' + resp.data.profileImageFile;
            } else {
                resp.data.profileImageFile = '/assets/icons/no-picture.svg'
            }

            resp.data.userAccesses?.sort((a, b) => {
                if (a.userAccessID !== 0 && b.userAccessID === 0) {
                    return -1;
                } else if (a.userAccessID === 0 && b.userAccessID !== 0) {
                    return 1;
                } else {
                    return 0;
                }
            });

            resp.data.userAccesses?.forEach(a => {
                a.selected = !!a.userAccessID;
            });
            return resp;
        }));
    }

    upsert(params: Partial<User>) {
        const url = `${this.baseUrl}`;
        return this.httpClient.post<HttpResponse<string>>(url, params);
    }

    uploadPicture(userName: any, body: any) {
        const url = `${this.baseUrl}/${userName}/profile-picture`
        return this.httpClient.post<HttpResponse<string>>(url, body)
    }

    triggerAction(actionCode: string, userName: string) {
        const actionConfiguration: ActionConfig = {
            url: `${this.baseUrl}/action`,
            actionCode,
            id1: userName,
            section: 'User',
            notes: 'USER',
        }
        return this.actionService.triggerAction(actionConfiguration);
    }

    triggerMultiAction(body: any[]) {
        return this.httpClient.post(`${this.baseUrl}-multi-action`, body);
    }

    getAccessList(username: string) {
        return this.httpClient.get<HttpResponse<UserAccessAuth[]>>(`${this.baseUrl}/${username}/objects`);
    }

}
